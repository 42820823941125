<template lang="html">
  <component
    :is="expanded ? 'div' : 'button'"
    class="project-card"
    :class="{
      'is-expanded': expanded,
    }"
    @click="setActive"
  >
    <div v-if="shapeName" class="project-card__shape" :class="shapeClass">
      <component :is="shapeToUse" />
    </div>

    <button
      v-if="expanded"
      class="project-card__close"
      title="Close project"
      @click.stop="closeActive"
    >
      <Icon id="Cross" class="w-8 md:w-12" />
    </button>

    <div class="project-card__content">
      <h2 class="project-card__title">{{ title }}</h2>
      <p v-if="excerpt && !expanded" class="project-card__intro">{{ excerpt }}</p>
    </div>

    <div v-if="!expanded" class="project-card__indicators">
      <SaveIndicator :uid="pageId" :count="likeCount" />
    </div>

    <div v-if="expanded" class="project-card__expanded">
      <div class="project-card__intro" v-html="intro" />
      <Button v-if="url" :href="url" round>Read more</Button>

      <div class="project-card__related">
        <LinkList v-if="expanded" title="In this section" mode="children" :page-id="pageId" />

        <div v-if="relatedStory" class="project-card__related">
          <h3>Related story</h3>

          <img
            v-if="relatedStory.thumbnail"
            :src="relatedStory.thumbnail.url"
            :alt="relatedStory.thumbnail.alt"
          />

          <span v-for="(topic, index) in relatedStory.topics" :key="topic.id">
            {{ topic.name }}
            <span v-if="index !== relatedStory.topics.length - 1">,</span>
          </span>

          <h4>{{ relatedStory.topic }}</h4>
          <p>{{ relatedStory.excerpt }}</p>
          <a :href="relatedStory.url">Read more</a>
        </div>

        <div v-if="download" class="project-card__download">
          <h3 class="mb-8">Related download</h3>

          <Download
            :title="download.title"
            :url="download.url"
            :type="download.type"
            :filesize="download.filesize"
          />
        </div>
      </div>

      <div class="flex flex-row">
        <SaveButton :uid="pageId" />
      </div>
    </div>
  </component>
</template>

<script>
import Vue from 'vue';
import LinkList from '@/components/project_page/LinkList.vue';
import Icon from '@/components/Icon.vue';
import Download from '@/components/Download.vue';
import Button from '@/components/Button.vue';
import SaveIndicator from '@/components/SaveIndicator.vue';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'ProjectCard',
  components: {
    Download,
    Icon,
    LinkList,
    Button,
    SaveButton,
    SaveIndicator,
    ShapeU: Vue.component('ShapeU', {
      template: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 216"><path d="M0,70V35A34.72,34.72,0,0,1,35,0C53.5,0,68.9,15.5,69,35V70c-.1,40.4,32.6,74.1,75,76h11c43.3-1.9,76.1-35,76-76V35c.1-19.1,15.5-34.6,34-35,19.6.4,35,15.9,35,35V70c0,78.6-61.3,141.6-143,146H141C61.7,211.7,0,147.4,0,70"/></svg>'
    }),
    ShapeI: Vue.component('ShapeI', {
      template: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231 64"><path  d="M231,32h0a32,32,0,0,1-32,32H33A32,32,0,0,1,1,32H1A32,32,0,0,1,33,0H199A32,32,0,0,1,231,32Z"/></svg>'
    }),
    ShapeO: Vue.component('ShapeO', {
      template: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path  d="M50,100A50,50,0,1,0,0,50a50,50,0,0,0,50,50"/></svg>'
    }),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      default: null,
    },
    intro: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
    },
    pageId: {
      type: Number,
      default: null,
    },
    relatedStory: {
      type: Object,
      default: () => {},
    },
    download: {
      type: Object,
      default: () => {},
    },
    shapeName: {
      type: String,
      default: null,
    },
    shapeColor: {
      type: String,
      default: null,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    slug: {
      type: String,
      required: true,
    },
    likeCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    shapeClass() {
      return `text-${this.shapeColor}`;
    },
    shapeToUse() {
      const shapeType = this.shapeName.charAt(0);

      switch (shapeType) {
        case 'U':
          return 'ShapeU';
        case 'I':
          return 'ShapeI';
        default:
          return 'ShapeO';
      }
    },
  },
  methods: {
    setActive() {
      this.clearActive();
      this.$emit('setActive');
    },
    clearActive() {
      this.$emit('clearActive');
    },
    closeActive() {
      // this.$router.push({ hash: null, query: { noscroll: true } });
      window.location.hash = '';
      this.clearActive();
    },
  },
};
</script>
